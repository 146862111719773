import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Hero from "../components/Hero";
import Seo from "../components/Seo";
import { Button, Typography, Row, Col, List } from "antd";
import "antd/dist/antd.css";
import Article from "../components/Article/";
import Headline from "../components/Article/Headline";
const { Title, Paragraph } = Typography;
import servicios from "../../content/datos-servicios";
import ColumnServicio from "../components/ColumnServicio";
import Servicio from "../components/Servicio";

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const {
      data: {
        bgDesktop: {
          resize: { src: desktop }
        },
        bgTablet: {
          resize: { src: tablet }
        },
        bgMobile: {
          resize: { src: mobile }
        },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = this.props;

    const backgrounds = {
      desktop,
      tablet,
      mobile
    };

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <React.Fragment>
              <Hero
                scrollToContent={this.scrollToContent}
                backgrounds={backgrounds}
                theme={theme}
              />
              <Article theme={theme} style={{ paddingTop: "3em" }}>
                <h2>
                  Estamos dedicados a desarrollar soluciones TI basadas en software libre. Nuestro
                  fuerte es el desarrollo de grandes sistemas empresariales en plataforma Web, y
                  aplicaciones móviles nativas.
                </h2>
              </Article>

              <Article theme={theme} style={{ paddingTop: "3em" }}>
                <header>
                  <Headline title="Servicios" theme={theme} />
                </header>

                <Row gutter={8}>{servicios.map(s => <ColumnServicio key={s.nombre} {...s} />)}</Row>

                <Title style={{ marginTop: "2em" }} level={2}>
                  Algo más
                </Title>

                <Paragraph style={{ marginBottom: "2em" }}>
                  <p>Compilaciones que a lo largo de los años hemos publicado.</p>
                  <Paragraph>
                    <a
                      href="http://www.welinux.cl/wordpress/alawelinux/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginRight: "1em" }}
                    >
                      <Button type="primary">Blog</Button>
                    </a>
                    <a
                      href="http://www.welinux.cl/hans/tips.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button type="primary">Consejos y trucos (antiguos)</Button>
                    </a>
                  </Paragraph>
                </Paragraph>
              </Article>
            </React.Fragment>
          )}
        </ThemeContext.Consumer>

        <hr ref={this.separator} />

        <Seo facebook={facebook} />

        <style jsx>{`
          hr {
            margin: 0;
            border: 0;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

//hero-background
